import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"

import {Layout} from "../components/Layout";

export default function IndexPage() {
    return (
        <Layout restrictedWidth={false}
                pageTitle={"De MTB specialist regio Deventer!✓ | Steven's bike service"}
                metaDescription={"Jouw mountainbike voor onderhoud of reparatie? Neem dan nu contact op met Steven's bike service! Dé MTB specialist van regio Deventer!✓"}>

            <div className={"fixed top-20 z-0 p-0"}>
                <StaticImage className={"landscape:w-screen portrait:h-screen"}
                             src={"../images/sbs-werkplaats-wand.jpg"} alt={"Steven"}/>
            </div>
            <div className={"fixed top-20 md:top-[12vh] z-10 w-full"}>
                <div className={"h-[25vh]"}></div>
                <div className={"max-w-8xl mx-auto"}>
                    <h1
                        className={"lg:leading-relaxed md:order-1 text-white text-center text-3xl md:text-5xl lg:text-6xl order-4 md:order-4 mb-10"}>
                        Dé <span className={"font-bold"}>mountainbike specialist</span> regio <span
                        className={"font-bold"}>Deventer</span>
                    </h1>
                </div>
            </div>

            <div className={"h-[65vh]"}></div>

            <div className={"bg-white z-20 w-full pb-10"}>
                <div className=" max-w-6xl mx-auto md:grid md:grid-cols-2 lg:content-between">

                    <p className={"leading-relaxed md:leading-relaxed lg:leading-relaxed mt-5 px-2 col-span-2 text:xl md:text-2xl lg:text-2xl pt-5"}>
                        Mountainbikers in de omgeving van Deventer - moet jouw mountainbike onderhouden of gerepareerd
                        worden? Kom dan langs bij Steven's bike service!
                    </p>
                    <p className={"px-2 col-span-2 text:xl md:text-2xl lg:text-2xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-5"}>
                        Steven's bike service is dé mountainbike specialist van Deventer en omstreken. Ik weet alles
                        van mountainbike onderhoud én reparatie, uiteraard van alle merken en soorten mountainbikes. Dus
                        als je remmen niet meer werken, je versnellingen niet meer schakelen of je fiets een
                        onderhoudsbeurt nodig heeft, ik help je graag!
                    </p>

                    {/* Neem contact op */}
                    <div className={"py-10 col-span-2"}>
                        <div
                            className="p-4 w-full text-center bg-white rounded-lg border shadow-md sm:p-8 bg-gray-200 text-black">
                            <p className={"px-2 italic col-span-2 text:xl md:text-2xl lg:text-2xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-5"}>
                                Neem nu contact met mij op en ontdek wat ik voor jouw mountainbike kan betekenen. Tot
                                snel!</p>
                            <a href={"contact"}>
                                <button type="button"
                                        className={"md:w-1/2 text:xl lg:text-2xl text-black bg-white hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-base px-auto p-5 text-center"}>
                                    Neem nu contact op!
                                </button>
                            </a>
                        </div>
                    </div>

                    {/* Professionaliteit */}
                    <div className={"col-span-2 md:grid md:grid-cols-2 gap-16"}>
                        <div className={"ml-auto"}>
                            <StaticImage src={"../images/sbs-werkplaats.jpg"} alt={"Steven"}
                                         className={"shadow-lg bg-white shadow-gray-400"}/>
                        </div>
                        <div className={"h-full"}>
                            <h2 className={"px-2 text-3xl md:text-4xl lg:text-5xl font-bold my-10"}>Professional</h2>
                            <p className={"px-2 text:xl md:text-2xl lg:text-2xl leading-relaxed lg:leading-relaxed mb-16"}>
                                Steven, al sinds 2013 als professional in het vak ...
                            </p>
                        </div>
                    </div>

                    {/* Liefde voor de fiets */}
                    <div className={"col-span-2 md:grid md:grid-cols-2 gap-16"}>
                        <div className={"order-1 md:order-2 ml-auto"}>
                            <StaticImage src={"../images/sbs-liefde.jpg"} alt={"Steven"}
                                         className={"shadow-lg bg-white shadow-gray-400"}/>
                        </div>
                        <div className={"order-2 md:order-1 h-full leading-loose"}>
                            <h2 className={"px-2 text-3xl md:text-4xl lg:text-5xl font-bold my-10"}>Liefde voor de
                                fiets</h2>
                            <p className={"px-2 text:xl md:text-2xl lg:text-2xl leading-relaxed lg:leading-relaxed mb-16"}>
                                en je onderhoud en reparatie dan door een fanatiek fietser
                                gedaan wordt met liefde voor de mountainbike ...
                            </p>
                            <a href={"/mtb-onderhoudsbeurten"}>
                                <button type="button"
                                        className={"text:xl lg:text-2xl w-full text-black bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg mb-6 px-auto py-3.5 text-center"}>
                                    Bekijk de onderhoudsbeurten!
                                </button>
                            </a>
                        </div>
                    </div>

                    {/* Groen en duurzaam */}
                    <div className={"col-span-2 md:grid md:grid-cols-2 gap-16"}>
                        <div className={"ml-auto mt-8"}>
                            <StaticImage src={"../images/sbs-EcoPartsWasher.jpg"} alt={"Steven"}
                                         className={"shadow-lg bg-white shadow-gray-400"}/>
                        </div>
                        <div className={"h-full align-middle"}>
                            <h2 className={"px-2 text-3xl md:text-4xl lg:text-5xl font-bold my-10"}>Groen &
                                duurzaam</h2>
                            <p className={"px-2 text:xl md:text-2xl lg:text-2xl leading-relaxed lg:leading-relaxed mb-16"}>
                                de werkplaats is groen en duurzaam, zoals de stroom van eigen zonnepanelen
                                (nul-op-de-meter), biologisch afbreekbare producten en de Muc-Off Eco parts washer...
                            </p>
                        </div>
                    </div>

                    {/* Officieel dealer */}
                    <div className={"col-span-2 md:grid md:grid-cols-2 gap-16"}>
                        <div className={"order-1 md:order-2 ml-auto"}>
                            <StaticImage src={"../images/sbs-logos.jpg"} alt={"Steven"}
                                         className={"shadow-lg bg-white shadow-gray-400"}/>
                        </div>
                        <div className={"order-2 md:order-1 h-full leading-loose"}>
                            <h2 className={"px-2 text-3xl md:text-4xl lg:text-5xl font-bold my-10"}>Dealer van</h2>
                            <p className={"px-2 text:xl md:text-2xl lg:text-2xl leading-relaxed lg:leading-relaxed mb-16"}>
                                Shimano, Sram, Muc-Off, Squirt, Rockshox, Fox, AGU
                            </p>
                        </div>
                    </div>

                    {/* Alle merken */}
                    <div className={"col-span-2 md:grid md:grid-cols-2 gap-16"}>
                        <div className={"ml-auto"}>
                            <StaticImage src={"../images/sbs-mtb-large.jpg"} alt={"Steven"}
                                         className={"shadow-lg bg-white shadow-gray-400"}/>
                        </div>
                        <div className={"h-full"}>
                            <h2 className={"px-2 text-3xl md:text-4xl lg:text-5xl font-bold my-10"}>Alle merken</h2>
                            <p className={"px-2 text:xl md:text-2xl lg:text-2xl leading-relaxed lg:leading-relaxed mb-16"}>
                                Steven doet onderhoud & reparatie van alle merken ...
                            </p>
                        </div>
                    </div>

                    {/* Alle routes */}
                    <div className={"col-span-2 md:grid md:grid-cols-2 gap-16"}>
                        <div className={"order-1 md:order-2 ml-auto"}>
                            <StaticImage src={"../images/sbs-routes.jpg"} alt={"Steven"}
                                         className={"shadow-lg bg-white shadow-gray-400"}/>
                        </div>
                        <div className={"order-2 md:order-1 h-full leading-loose"}>
                            <h2 className={"px-2 text-3xl md:text-4xl lg:text-5xl font-bold my-10"}>Alle routes</h2>
                            <p className={"px-2 text:xl md:text-2xl lg:text-2xl leading-relaxed lg:leading-relaxed mb-16"}>
                                hij kent alle mtb-routes rondom Deventer als geen ander, voor het beste advies.
                            </p>
                        </div>
                    </div>
                </div>

                {/*<div className={"bg-white z-20 w-full"}>*/}
                {/*    <div className="max-w-6xl mx-auto md:grid md:grid-cols-2 lg:content-between p-10 gap-16">*/}

                {/*        /!*<div className="max-w-6xl mx-auto md:grid gap-16 md:grid-cols-2 p-5 ">*!/*/}
                {/*        <Card>*/}
                {/*            <h2 className="my-5 text-2xl font-bold text-center tracking-tight text-gray-900 ">*/}
                {/*                Ik ben alleen open<br/> op afspraak*/}
                {/*            </h2>*/}
                {/*            <div className="w-full grid gap-2 p-5 border border-b-0 border-gray-200 ">*/}
                {/*                <StaticImage src={"../images/sbs-contact.jpg"} alt={"Contact werkplaats"}*/}
                {/*                             className={"mx-auto my-1 shadow-xl "}/>*/}
                {/*            </div>*/}
                {/*            <p className="mb-3 px-10 text-center lg:text-xl font-normal text-gray-700 ">*/}
                {/*                <span className={"font-bold"}>Steven's bike service</span><br/>*/}
                {/*                Steven van Vliet<br/>*/}
                {/*                van der Waalsstraat 35<br/>*/}
                {/*                7424 GW&nbsp;&nbsp;Deventer<br/>*/}
                {/*                telefoonnummer: <a href={"tel: 0612712347"}*/}
                {/*                                   className={"text-blue-600 hover:underline"}> 0612712347</a><br/>*/}
                {/*                mail: <a*/}
                {/*                href={"mailto:info@stevensbikeservice.nl?subject=Mail van de stevenbikeservice.nl website"}*/}
                {/*                className={"text-blue-600 hover:underline"}*/}
                {/*                target={"_blank"}>info@stevensbikeservice.nl</a>*/}
                {/*            </p>*/}
                {/*        </Card>*/}
                {/*        <Card>*/}
                {/*            <h2 className="my-5 px-4 text-2xl font-bold text-center tracking-tight text-gray-900 ">*/}
                {/*                Route plannen met Google Maps*/}
                {/*            </h2>*/}
                {/*            <div className="border border-gray-200 shadow-xl m-7">*/}
                {/*                <div className="h-96 w-full mx-auto">*/}
                {/*                    <iframe className={"h-96 w-full"}*/}
                {/*                            title='Google maps kaart'*/}
                {/*                            id="gmap_canvas"*/}
                {/*                            src="https://maps.google.com/maps?q=van%20der%20waalsstraat%2035%20deventer&t=&z=15&ie=UTF8&iwloc=&output=embed"/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </Card>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </Layout>
    )
}
